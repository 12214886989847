import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';

const AFKGuidesSpending: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Spending guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_spending.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Spending guide</h1>
          <h2>
            Best packs to buy in AFK Journey if you plan to spend on the game.
          </h2>
          <p>
            Last updated: <strong>26/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          AFK Journey is a very free to play friendly game and while spending
          will help you to get ahead of other players, you're basically paying
          to progress faster. Also, while the game has a few great value
          'monthly' packs, once you buy them out, additional spending requires
          massive pockets. So you either stay F2P, buy the few monthly packs or
          whale a lot.
        </p>
        <p>
          <strong>
            Before spending anything or buying yet another pack, just ask
            yourself if you really need and most importantly, if you can afford
            it.
          </strong>
        </p>
        <SectionHeader title="Best value packs" />
        <h5>1. The usual top-tier 1$ offer [$1]</h5>
        <StaticImage
          src="../../../images/afk/generic/spending_1.webp"
          alt="Guides"
        />
        <p>
          As always, the 1$ offer you will be given at the start of the game has
          the best value in the whole game. The 10{' '}
          <AFKItem name="Epic Invite Letter" /> it gives allow you to instantly
          reach the first pity on the Epic Recruitment banner, so you will start
          the game with another <AFKItem name="S-Level" /> character. And
          because you can setup the wishlist for that banner instantly, you will
          obtain one of the 5 characters of your choosing, making the early
          progression even easier. It's basically a 20% chance to get the best
          tank in the game -{' '}
          <AFKCharacter mode="inline" slug="thoran" enablePopover /> - instantly
          when you start the game.
        </p>
        <p>
          <strong>Be careful!</strong> This pack will vanish after 2 hours of it
          appearing and never return. So you just have one chance of buying it.
        </p>
        <StaticImage
          src="../../../images/afk/generic/spending_2.webp"
          alt="Guides"
        />
        <p>
          And spending that 1$ will also give you the first-purchase special
          pack which contains{' '}
          <AFKCharacter mode="inline" slug="rowan" enablePopover /> who is one
          of the strongest supports in the game. On top of him, you will also
          get 2x <AFKItem name="Invite Letter" /> and the{' '}
          <strong>Ironwall</strong> Spell Artifact which will make your tank
          last longer.
        </p>
        <h5>2. Secret Travelogue [$10 or $20]</h5>
        <StaticImage
          src="../../../images/afk/generic/spending_3.webp"
          alt="Guides"
        />
        <p>
          This is the AFK Journey version of the popular battle pass. On top of
          a lot of resources and <AFKItem name="Epic Invite Letter" /> you will
          also be able to obtain a selector box with a{' '}
          <AFKItem name="S-Level" /> On the PTR server there were 4 choices and
          you could pick one, but as far as we are aware, the heroes in the
          selector box will change on release. So we will update the guide once
          we know who is available there.
        </p>
        <p>
          The more expensive version of the battle pass will instantly get you
          the first 20 levels, a border, cosmetics items and 10 more{' '}
          <AFKItem name="Epic Invite Letter" />.
        </p>
        <h5>3. Classic Gazette [$5]</h5>
        <StaticImage
          src="../../../images/afk/generic/spending_4.webp"
          alt="Guides"
        />
        <p>
          The Classic Gazette gives you in total 12000{' '}
          <AFKItem name="Diamonds" /> (3000 upfront and 9000 over the course of
          the month) and VIP Points.
        </p>
        <h5>4. Premium Gazette [$15]</h5>
        <StaticImage
          src="../../../images/afk/generic/spending_5.webp"
          alt="Guides"
        />
        <p>
          While the Classic Gazette gives you <AFKItem name="Diamonds" />, the
          premium variant gives you 70 <AFKItem name="Epic Invite Letter" /> (10
          upfront and then 2 every day) and VIP Points.
        </p>
        <h5>5. Growth Bundle [$30]</h5>
        <StaticImage
          src="../../../images/afk/generic/spending_6.webp"
          alt="Guides"
        />
        <p>
          The Growth Bundle gives a staggering amount of 18000{' '}
          <AFKItem name="Diamonds" />, 60 <AFKItem name="Invite Letter" /> and
          60 <AFKItem name="Epic Invite Letter" /> but there's a catch. You have
          to clear up to 951 AFK Stage to obtain them all. Still, the should
          take a person who considers buying the bundle roughly a month.
        </p>
        <SectionHeader title="Welcome Packs" />
        <StaticImage
          src="../../../images/afk/generic/spending_7.webp"
          alt="Guides"
        />
        <p>
          The Welcome Packs are active only for the first 2 weeks of you
          creating your characters and then they will vanish. Also compared to
          the previous packs, you will need to buy a special in-game currency -{' '}
          <strong>Dragon Crystal</strong> - before you will be able to buy any
          of these and to make your life easier, we slapped the price in $ on
          the image above, so you will know how much they cost without going
          back and forth.
        </p>
        <p>
          Overall all packs available here can be considered good value and a
          lot of whales will jump onto the $90 pack as it contains{' '}
          <AFKCharacter mode="inline" slug="thoran" enablePopover /> who is the
          best tank in the game.
        </p>
        <SectionHeader title="Special Timed Packs" />
        <p>
          Every now and then, various packs will appear that you can only buy
          within 2 hours or they will go away. We can't really review those
          packs and rate them here one by one, because there's quite a lot of
          them - and their value also varies (you can get anything from $1 up to
          $50).
        </p>
        <p>
          Overall they are great value as they give a decent amount of pulls and
          even <AFKItem name="S-Level" /> characters. Like for example this pack
          that costs $41 and gives a <AFKItem name="Hypogean" /> character{' '}
          <AFKCharacter mode="inline" slug="reinier" enablePopover />
        </p>
        <StaticImage
          src="../../../images/afk/generic/spending_9.webp"
          alt="Guides"
        />
        <SectionHeader title="Whale Zone" />
        <StaticImage
          src="../../../images/afk/generic/spending_8.webp"
          alt="Guides"
        />
        <p>
          If you're not a whale, this is where you stop. While the packs above
          in the previous sections have a great value for low and 'dolphin'
          spenders, once they're gone, what's left is just targeted toward
          whales. The packs in this shop refresh every 2 weeks and even for
          whales I would say that you should wait for the timer as the permanent
          packs have or raw currency deals have terrible value.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesSpending;

export const Head: React.FC = () => (
  <Seo
    title="Spending guide | AFK Journey | Prydwen Institute"
    description="Best packs to buy in AFK Journey if you plan to spend on the game."
    game="afk"
  />
);
